import { useCallback } from "react"

const useValidator = () => {
  const validateUrlAvailability = useCallback(async (url: string) => {
    try {
      const response = await fetch(`/api/validateUrl?url=${encodeURIComponent(url)}`);
      const data = await response.json();
      return data.available;
    } catch (error) {
      return false;
    }
  }, []);
  return { validateUrlAvailability };
}

export default useValidator;